
import { getVariantTitle } from "~/utils/shopify-utils";
import { getAttribute } from "~/utils";
import { tagableMixin } from "~/mixins";

let DIMENSION = "60";

export default {
  mixins: [tagableMixin],

  props: { lineItem: { type: Object, default: () => {} } },

  computed: {
    variant() {
      return this?.lineItem?.variant;
    },
    componentToRender() {
      return this.lineItem.isDonationItem || this.lineItem.isBonusItem
        ? "div"
        : "NuxtLink";
    },
    itemTitle() {
      const variantTitle = getVariantTitle({
        variant: this.variant,
        isMultiVariant: this.isMultiVariant,
      });

      return `${this.variant.product.title} ${variantTitle}`;
    },
    tags() {
      return this.variant?.product?.tags ?? [];
    },
    imageOptions() {
      const image = this.variant?.image ?? {};
      const variantImageSrc = getAttribute("_variant_image_src", this.lineItem);

      return {
        src: this.lineItem.isDonationItem
          ? image.w_160
          : variantImageSrc ?? image.w_60_h_60,
        webpSrc: this.lineItem.isDonationItem
          ? image.w_160_webp
          : variantImageSrc ?? image.w_60_h_60_webp,
        alt: this.itemTitle,
        widths: [DIMENSION],
        width: DIMENSION,
        height: DIMENSION,
      };
    },
    pdpUrl() {
      return this.lineItem.isDonationItem || this.lineItem.isBonusItem
        ? ""
        : `/products/${this.variant.product.handle}?variant=${this.variant.id}`;
    },
  },
};
