import { render, staticRenderFns } from "./MiniBagItem.vue?vue&type=template&id=65eaccf4&"
import script from "./MiniBagItem.vue?vue&type=script&lang=js&"
export * from "./MiniBagItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ShopifyImage: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/ShopifyImage.vue').default,MiniBagPrice: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/mini-bag-modal/MiniBagPrice.vue').default})
