
// import { pathOr, path } from "ramda";

export default {
  props: { lineItem: { type: Object, default: () => {} } },

  computed: {
    price() {
      const totalAmount = Number(this.lineItem.cost.totalAmount?.amount);
      return Number.isNaN(totalAmount) ? 0 : totalAmount;
    },
    compareAtPrice() {
      const totalDiscount =
        this.lineItem.discount + this.lineItem.bundleDiscount;

      return totalDiscount
        ? this.price + totalDiscount
        : (this.lineItem.cost.compareAtAmountPerQuantity?.amount ?? 0) *
            this.lineItem.quantity;
    },
  },
};
