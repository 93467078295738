
import { mapState, mapGetters } from "vuex";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";

export default {
  components: { PerfectScrollbar },

  async fetch() {
    const cartId = this.$cart.getCartId();
    await this.$store.dispatch("bag/GET_CART", cartId);
  },

  fetchOnServer() {
    return this.$route.path === "/cart";
  },

  computed: {
    ...mapState("app", ["isMiniBagOpen"]),
    ...mapState("bag", ["miniCart"]),
    ...mapGetters("bag", [
      "visibleLineItems",
      "donationLineItem",
      "addedAssemblyAmount",
      "bonusLineItem",
    ]),
    lineItems() {
      return [
        ...this.visibleLineItems,
        this.bonusLineItem,
        this.donationLineItem,
      ].filter(Boolean);
    },
    subTotalWithoutAssembly() {
      return this.addedAssemblyAmount
        ? Number(this.miniCart.totalPrice) - Number(this.addedAssemblyAmount)
        : this.miniCart.totalPrice;
    },
  },

  methods: {
    onClickViewBag() {
      this.closeMiniBag();
      this.$router.push({
        path: "/cart",
      });
    },
    closeMiniBag() {
      this.$store.dispatch("app/closeMiniBag");
    },
  },
};
