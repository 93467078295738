import { render, staticRenderFns } from "./MiniBagModal.vue?vue&type=template&id=6ad0e7ae&"
import script from "./MiniBagModal.vue?vue&type=script&lang=js&"
export * from "./MiniBagModal.vue?vue&type=script&lang=js&"
import style0 from "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css?vue&type=style&index=0&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MiniBagEmpty: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/mini-bag-modal/MiniBagEmpty.vue').default,MiniBagItem: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/mini-bag-modal/MiniBagItem.vue').default,AppButton: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/common/AppButton.vue').default})
