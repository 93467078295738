
export default {
  props: {
    src: { type: String, default: "" },
    webpSrc: { type: String, default: "" },
    alt: { type: String, default: "" },
    widths: { type: Array, default: () => [] },
    sizes: { type: String, default: "" },
    cropTop: { type: Boolean, default: false },
    aspectRatio: { type: String, default: "none" },
    preload: {
      type: String,
      default:
        "data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABBCAQAAACNislaAAAATUlEQVR42u3PAQ0AAAwCoNs/tO/hoAFpb0JEREREREREREREREREREREREREREREREREREREREREREREREREREREREREREREREREpiMP85KBwEiuOPgAAAAASUVORK5CYII=",
    },
    width: { type: String, default: "" },
    height: { type: [String, Number], default: "" },
    imageClass: { type: String, default: "" },
    // used 'imageClass' as a prop to add classes to child components of picture tag
    // eg. source and img elements.
  },

  computed: {
    cropToTop() {
      return this.cropTop === true ? "_crop_top" : "";
    },
    nativeLazySupported() {
      return true; // "loading" in HTMLImageElement.prototype;
    },
    imageProps() {
      return {
        height: this.height,
        width: this.width,
        sizes: this.sizes,
        class: `${this.imageClass} lazy w-full`,
        alt: this.alt,
        title: this.alt,
        loading: "lazy",
      };
    },
    srcset() {
      return this.widths.map(w => this.getModifiedSrc(w, this.src));
    },
    webpSrcset() {
      return this.widths.map(w => this.getModifiedSrc(w, this.webpSrc));
    },
  },

  methods: {
    getModifiedSrc(width, imageSrc) {
      return `${imageSrc} ${width}w`;
    },
  },
};
